module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sammons-industrial-site","short_name":"sammons","start_url":"/","background_color":"#003f77","theme_color":"#e9a53a","display":"minimal-ui","icon":"src/images/Sammons_Favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a1dfee51e2984c88d202ab1e005f308"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
